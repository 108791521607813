<template>
  <div class="container-fixed">
    <div class="intro-box">
      <div class="title-box animate__animated animate__fadeInUp">
        <div class="animate__animated animate__fadeInLeft">
          <div class="intro-box-title">Our Service</div>
          <hr/>
          <div class="intro-box-sub">우리는 귀사의 비즈니스 목표를 달성하는 데 도움이 되도록
  설계된 효과적인 기술 문서 작성 및 번역, 현지화 서비스 그리고 고도화된 솔루션을 귀사에 제공하는데 집중하고 있습니다.<br> <br>텍스트리의 프로젝트 관리자가 이끄는 팀은 귀하가 요구하는 기술 문서 컨텐츠를 제시간에 예산에 맞게 제공하도록 보장하고 있습니다. 각 팀은 프로젝트에 필요한 기술과 경험을 보유한 전문가로 구성되며 아웃소싱이 아닌 텍스트리의 정직원입니다.</div>
        </div>
      </div>
      <div class="services-body">
        <div class="service-contents">
          <a class="service-content animate__animated animate__fadeInUp animate__delay-1s" href="/documentation">
            <img src="/assets/icons/services-document.gif">
            <div>
              <a class="service-link">Documentation Services</a>
              <p class="service-desc">기술 매뉴얼은 기업이 비즈니스 파트너를 얼마나 효과적으로 지원하는지 그리고 기업의<br>제품을 고객이 받고 효율적으로 사용하는 방법을 제공하는데 중요한 역할을 합니다.</p>
            </div>
          </a>
          <a class="service-content animate__animated animate__fadeInUp animate__delay-1s" href="/solution">
            <img src="/assets/icons/services-solution.gif">
            <div>
              <a class="service-link">Solution Management Services</a>
              <p class="service-desc">TEXTree 문서관리 시스템을 사용하면 포괄적이고 사용자 친화적인 인터페이스를 사용하여 여러 버전의 다국어 문서를 만들고 관리할 수 있습니다.</p>
            </div>
          </a>
        </div>
        <div class="service-preview animate__animated animate__fadeInUp animate__delay-2s">
          <img src="/assets/images/homepage/Images.png">
          <img src="/assets/images/homepage/Images1.png">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store'

export default {
  mounted () {
    store.commit('SET_NAV_THEME', 'lightLogoDark')
    store.commit('SET_MORE_THEME', 'dark')
    store.commit('SET_MOBILE_MORE_FILL', true)
  }
}
</script>

<style scoped>
.intro-box::before {
  animation: fadeInUp;
  animation-duration: 1s;
    content: " ";
    position: absolute;
    left: 0;
    width: calc(50% - 800px);
    height: 100%;
    background-color: #008ED6;
}
.intro-box {
  color: rgba(255,255,255,.35);
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
}
.intro-box .title-box {
  margin-top: 0;
  width: 37.5%;
  height: 100%;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #008ED6;
}
.intro-box .title-box > div {
  animation-delay: .5s;
  animation-duration: .75s;
}
.intro-box-title {
  color: rgba(255,255,255,1);
  padding: 24px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 64px;
  line-height: 75px;
  margin-top: 3em;
  margin-bottom: 1em;
}
.intro-box-sub {
  color: rgba(255,255,255,1);
  padding: 24px;
  font-family: "Nanum Gothic";
  font-size: 16px;
  line-height: 50px;
}
.link-title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
}
.link-desc {
  font-family: "Nanum Gothic";
  font-size: 16px;
  font-weight: normal;
}

.intro-box .services-body {
  width: calc(100% - 37.5% - 5em);
  height: calc(100% - 2em);
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5em;
  padding-top: 2em;
}

.service-content {
  display: flex;
  margin: 3em 0;
  text-decoration: none;
  color: white;
}
.service-content img {
  width: 100px;
  height: 100px;
  margin-top: -12px;
}
.service-link {
  color: #008ED6;
  font-family: "Nanum Gothic";
  font-size: 18px;
  font-weight: bolder;
}
.service-desc {
  color: #5D5D5D;
  font-family: "Nanum Gothic";
  font-size: 18px;
  font-weight: bold;
}
hr {
  width: 100px;
  background: #F1B500;
  height: 5px;
  border: none;
  margin-left: 24px;
  margin-top: 3em;
  margin-bottom: 4em;
}
.service-preview {
  display: flex;
  justify-content: center;
  height: auto;
  margin: 0 auto;
}
.service-preview img {
  width: calc(50% - 24px);
  padding: 0 12px;
}

@media(max-width: 1280px) {
  .intro-box-title {
    margin-top: 1em;
    font-size: 48px;
    line-height: 1.3;
    margin-bottom: 0;
  }
  hr {
    margin: 0 24px;
  }
  .intro-box-sub {
    line-height: 2;
  }
  .intro-box .services-body {
    width: calc(100% - 37.5% - 48px);
    padding: 24px;
  }
}

@media(max-width: 768px) {
  .intro-box {
    flex-direction: column;
    height: calc(100% - 64px);
  }
  .intro-box-sub {
    display: none;
  }
  .intro-box .title-box {
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 24px;
    background-image: url('/assets/images/homepage/Images.png');
    background-size: cover;
  }
  .intro-box .services-body {
    width: calc(100% - 48px);
  }
  .intro-box-title {
    font-size: 24px;
  }
  .service-preview {
    display: none;
  }
  .service-desc {
    font-size: 16px;
  }
  .service-contents {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .service-content {
    margin: 0;
  }
}


@media(max-width: 425px) {
  .container-fixed {
    background-position: 64%;
  }
  .service-link {
    color: #008ED6;
    font-family: "Nanum Gothic";
    font-size: 18px;
    font-weight: bolder;
    text-decoration: underline;
  }
  .service-desc {
    color: #5D5D5D;
    font-family: "Nanum Gothic";
    font-size: 12px;
    font-weight: bold;
  }
}

@media(max-height: 680px) {
  .intro-box .title-box {
    padding-top: 66px;
  }
}
</style>
